
load_td_editor = function() {

	var editors = [];

	$('.ewrapper').each(function(idx, wrapper) {
		setup_tdeditor(wrapper);
	});

	// The content of the editable div is copied to the form form field prior to submitting the form
	$("#wysi-editable-form").submit(function() {
			ehtml = $("#editable").html();
			$("#edited-content").val(ehtml);
	});

	$("#file-upload-button").change(function(e) {
	  var input = this;
	  if (input.files && input.files[0]) {
	      var reader = new FileReader();
	        reader.onload = function (e) {
	            $('#upload-image').attr('src', e.target.result);
	        };
	        reader.readAsDataURL(input.files[0]);
	    }
	});


}

function matchscroll() {
  pos = $("#device-scroll-left").scrollTop();
  $("#device-scroll-right").scrollTop(pos);
}

// Modals
close_modal = function(event, modid) {
  $(modid).hide();
  event.preventDefault();
};

show_modal = function(event, modid) {
  $(modid).show();
  event.preventDefault();
};

update_title = function(event) {
  var title = $('#title_field').val();
  $('#article_title').text(title);
  $('#title_hidden').val(title);
  $('#edit-modal').hide();
  event.preventDefault();
}
