$(document).on('turbolinks:load', function() {

  if ($("#td-editor").length > 0) {
    load_td_editor();
    configureColorSliders();
  }

  // display image file selected for upload from local file system
  $("#file-upload-button").change(function(e) {
    var input = this;
    if (input.files && input.files[0]) {
        var reader = new FileReader();
            reader.onload = function (e) {
                $('#upload-image').attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
  });

});


sort_items = function(ui) {
	var appid = $('#sortable').data('appid');
	var sortlink = ui.item.attr('id');
	var linkid = sortlink.replace('cp-', '');
	var sortedIDs = $( "#sortable" ).sortable( "serialize" );
	$.ajax({
		url: '/admin/webapps/' + appid + '/sort/' + linkid,
		type: 'POST',
		data: sortedIDs,
		dataType: "script",
		success: function(data) {
		}
	});
};

// draggable and droppable called on page ready in page_functions.js
object_dropped = function(event, object) {

	var dropped = $(event.target);
	var dragged = object.draggable;

	var appid = $('#sortable').data('appid');
	var grpid = dropped.attr('id').replace('pg-', '');
	var linkid = dragged.attr('id').replace('cp-', '');

	$.ajax({
		url: '/admin/webapps/' + appid + '/move/' + grpid + '/' + linkid,
		type: 'PATCH',
		dataType: "script",
		success: function(data) {

		},
		error: function(x,e){
		}
	});
};


fill_gaps = function() {

};

close_modal = function(event, modid) {
  $(modid).hide();
  event.preventDefault();
};

show_modal = function(event, modid) {
  $(modid).show();
  event.preventDefault();
};


function openEditorTab(tabid) {
  var i;
  var x = $(".edit-tab");
  x.hide();
  $(tabid).show();
  return false;
}
