
var myIndex = 0;
var carousel_timer = null;

function carousel() {
    var i;
    var x = document.getElementsByClassName("td-banner-slides");
    if (x.length > 0) {
    	for (i = 0; i < x.length; i++) {
	       x[i].style.display = "none";
	    }
	    myIndex++;
	    if (myIndex > x.length) { myIndex = 1; }
	    x[myIndex-1].style.display = "block";
	    carousel_timer = setTimeout(carousel, 4000);
    }
}

$(document).ready(function() {
	if (carousel_timer != null) {
		clearTimeout(carousel_timer);
	}
	carousel();
});



function showDivs(n) {
  var i;
  var x = document.getElementsByClassName("td-banner-slides");
  if (x.length > 0) {
    if (n > x.length) {slideIndex = 1}
    if (n < 1) {slideIndex = x.length}
    for (i = 0; i < x.length; i++) {
       x[i].style.display = "none";
    }
    x[slideIndex-1].style.display = "block";
  }
}
